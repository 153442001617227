import React from 'react'
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import WellnessSurveyResult from "../components/Survey/WellnessSurveyResult";


export default function surveyresult() {
    return (
        <Layout>
            <SEO title="Find a good rehab" description="Good rehab for Substance abuse, Addiction Treatment and Banjo" />
            <WellnessSurveyResult data={typeof window !== 'undefined'? window.history.state.data: null}/>
        </Layout>
    )
}