import React, {Component} from "react"
import Title from "../Title"
import styles from "../../css/survey.module.css"

// import { useStaticQuery, graphql } from "gatsby"


class WellnessSurveyResult extends Component {

    constructor(props) {
        super(props);

        this.state = {
            result: props.data,
            total : 0.0,
            actualTotal:  0.0,
            actual: 0.0,
            displaytotal: 0.0
        };

        this.componentDidMount = this.componentDidMount.bind(this);

    }



    componentDidMount() {
        let total = 0.0;
        let actualTotal = 0.0;

        for (let dataKey in  this.props.data) {
            if(!dataKey.includes('question')) {
                actualTotal = actualTotal + 10;
                total += this.props.data[dataKey];
            }
        }
        console.log(total + ' ' + actualTotal);
        let calculatedActual = Math.round((total / actualTotal) * 100);
        let displayvalue = (calculatedActual/100).toFixed(2);
        this.setState({
            result: this.props.data,
            total : total,
            actualTotal: actualTotal,
            actual: calculatedActual,
            displaytotal: displayvalue
        });

        // console.log(' actuals',  this.state);
    }
    render() {
        return (
            <div>
                <section>
                    <Title title="Result for quality of " subtitle="Life"/>
                    <div className={styles.column}>
                        {/*<WellnessSurvey/>*/}
                        {/*{data.data} */}

                        <table className="charts-css column" id="surveychart">
                            <caption> Quality of life percentages</caption>

                            <tbody>
                            <tr>
                                <td style={{'--size': 'calc( 100 / 100 )'}}> Total 100%</td>
                            </tr>
                            <tr>
                                <td  style={{'--size': this.state.displaytotal}}> Your score {this.state.actual}%</td>
                            </tr>

                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                    <br/><br/>
                     <div className={styles.webnotsNotificationBox}>
                         The score shown here is informational only. If you have an addiction,
                         undergoing treatment in rehab may help you improve your quality of life.
                         If you are interested in rehab go to our rehabs menu and type in a zip code
                         for the area of your choice to get a list of rehabs and type of treatments available.
                        </div>
                        <br/><br/><br/><br/>
                </section>
            </div>
        )
    }
}

export default WellnessSurveyResult
